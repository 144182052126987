import React, { useEffect } from 'react';

import Nav from 'components/Nav/Nav';
import Content from 'components/Content/Content';

import withPageLayout from 'hocs/withPageLayout';

import { getCurrentView, getDomainPrefix } from 'utilities/domain-detection/domain-detection';
import { domainNames } from 'utilities/domain-data/domain-data';

import * as privacyData from 'static/content/privacy-policy/content.json';
import * as gbrPrivacyData from 'static/content/privacy-policy/gobankingratesContent.json';
import * as gfcPrivacyData from 'static/content/privacy-policy/gofreecreditContent.json';
import * as importantscoreData from 'static/content/privacy-policy/importantscoreContent.json';
import * as privacyLinks from 'static/content/privacy-policy/links.json';
import * as gbrPrivacyLinks from 'static/content/privacy-policy/gobankingratesLinks.json';
import * as gfcPrivacyLinks from 'static/content/privacy-policy/gofreecreditLinks.json';
import * as isPrivacyLinks from 'static/content/privacy-policy/importantscoreLinks.json';

const baseContent = privacyData.default;
const gbrContent = gbrPrivacyData.default;
const gfcContent = gfcPrivacyData.default;
const isContent = importantscoreData.default;
const baseLinks = privacyLinks.default;
const gbrLinks = gbrPrivacyLinks.default;
const gfcLinks = gfcPrivacyLinks.default;
const isLinks = isPrivacyLinks.default;

const switchContent = (domain) => {
  switch (domain) {
    case domainNames.CCT:
    case domainNames.GFA:
    case domainNames.YSO:
    case domainNames.GOIP:
      return [baseContent, baseLinks];
    case domainNames.GBR:
      return [gbrContent, gbrLinks];
    case domainNames.IS:
      return [isContent, isLinks];
    default:
      return [gfcContent, gfcLinks];
  }
};

const PrivacyPolicyPage = () => {
  const currentView = getCurrentView();
  const [content, links] = switchContent(currentView);

  // INFO: Redirect to GFA's privacy policy
  useEffect(() => {
    if (currentView === domainNames.GFA) {
      window.location = `https://${getDomainPrefix()}/privacy`;
    }
  }, [currentView]);

  if (currentView === domainNames.GFA) {
    return null;
  }

  return (
    <div className="container-fluid Page">
      <div className="row">
        <div className="col-12 col-lg-3 p-lg-0 position-relative">
          <Nav data={links} />
        </div>

        <div className="col-12 col-lg-9">
          <Content data={content} />
        </div>
      </div>
    </div>
  );
};

export default withPageLayout(PrivacyPolicyPage, {
  isIndexed: true,
  pageTitle: 'privacyPolicy',
});
