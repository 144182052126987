//
// Static page links
// Use 'next/link' with these links so '/tu' is prefixed
//

export const ABOUT_US = {
  text: 'About Us',
  href: 'https://www.gobankingrates.com/tu/footer/about',
  target: '_blank',
};

export const ADVERTISER_DISCLOSURE = {
  text: 'Advertiser Disclosure',
  href: 'https://www.gobankingrates.com/tu/footer/advertiser-disclosure',
  target: '_blank',
};

export const CANCELLATION = {
  text: 'Cancellation',
  href: 'https://www.gobankingrates.com/tu/footer/membercancel',
  target: '_blank',
};

export const CONTACT_US = {
  text: 'Contact Us',
  href: 'https://www.gobankingrates.com/tu/footer/contact',
  target: '_blank',
};

export const FAQ = {
  text: 'FAQ',
  href: 'https://www.gobankingrates.com/tu/footer/faq',
  target: '_blank',
};

export const MEMBER_LOGIN = {
  text: 'Login',
  href: 'https://www.gobankingrates.com/tu/footer/memberlogin',
  target: '_blank',
};

export const SECURITY = {
  text: 'Security',
  href: 'https://www.gobankingrates.com/tu/footer/security',
  target: '_blank',
};

//
// PersonalInformationDataApp (PIDA) links
//
// INFO: Make sure you're prepending the appropriate hostname for these.
//

export const PIDA_DO_NOT_SELL_MY_PERSONAL_INFORMATION = {
  href: '/do-not-sell',
  text: 'Do Not Sell My Personal Information',
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const PIDA_PRIVACY_POLICY_AND_TERMS_OF_USE = {
  text: 'Privacy Policy & Terms of Use',
  href: '/privacy',
  rel: 'noopener noreferrer',
  target: '_blank',
};

//
// GOBankingRates.com links
//

export const GBR_HOMEPAGE = {
  text: 'GOBankingRates',
  href: 'https://www.gobankingrates.com/',
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const GBR_BANKING = {
  text: 'Banking',
  href: 'https://www.gobankingrates.com/category/banking/',
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const GBR_CD = {
  text: 'CD',
  href: 'https://www.gobankingrates.com/cd-rates/',
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const GBR_CHECKING_ACCOUNTS = {
  text: 'Checking',
  href: 'https://www.gobankingrates.com/checking-accounts/',
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const GBR_CREDIT_CARDS = {
  text: 'Credit Cards',
  href: 'https://www.gobankingrates.com/credit-cards/',
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const GBR_CREDIT_SCORES = {
  text: 'GOBankingRates',
  href: 'https://www.gobankingrates.com/category/credit/credit-score/',
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const GBR_HOW_TO_INCREASE_CREDIT_SCORE = {
  text: 'How to Increase Credit Score?',
  href: 'https://www.gobankingrates.com/credit/credit-score/raise-credit-score-100-points/',
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const GBR_INVESTING = {
  text: 'Investing',
  href: 'https://www.gobankingrates.com/invest/',
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const GBR_MORTGAGE = {
  text: 'Mortgage',
  href: 'https://www.gobankingrates.com/mortgage/',
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const GBR_PERSONAL_LOANS = {
  text: 'Loans',
  href: 'https://www.gobankingrates.com/personal-loans/',
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const GBR_RETIREMENT_PLANNING = {
  text: 'Retirement',
  href: 'https://www.gobankingrates.com/retirement-planning/',
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const GBR_SAVINGS = {
  text: 'Savings',
  href: 'https://www.gobankingrates.com/savings-account/',
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const GBR_SAVING_MONEY = {
  text: 'Saving Money',
  href: 'https://www.gobankingrates.com/category/saving-money/',
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const GBR_WHAT_IS_A_GOOD_CREDIT_SCORE = {
  text: 'What is a Good Credit Score?',
  href: 'https://www.gobankingrates.com/credit/credit-score/what-good-credit-score-anyway/',
  rel: 'noopener noreferrer',
  target: '_blank',
};
