const REL_EXTERNAL = 'noopener noreferrer';

export const ABOUT_US = {
  _id: 1,
  text: 'About Us',
  href: 'https://__DOMAIN__/ecs/about',
  rel: REL_EXTERNAL,
  target: '_blank',
};

export const ADVERTISER_DISCLOSURE = {
  _id: 2,
  text: 'Advertiser Disclosure',
  href: 'https://__DOMAIN__/ecs/advertiser-disclosure',
  rel: REL_EXTERNAL,
  target: '_blank',
};

export const CONTACT = {
  _id: 3,
  text: 'Contact Us',
  href: 'https://__DOMAIN__/ecs/contact',
  rel: REL_EXTERNAL,
  target: '_blank',
};

export const DO_NOT_SELL = {
  _id: 4,
  text: 'Do Not Sell My Personal Information',
  href: '/manage-my-data?action=do_not_sell',
  rel: REL_EXTERNAL,
  target: '_blank',
};

export const FAQ = {
  _id: 6,
  text: 'FAQ',
  href: 'https://__DOMAIN__/ecs/faq',
  rel: REL_EXTERNAL,
  target: '_blank',
};

export const GO_BANKING_RATES = {
  _id: 7,
  text: 'GOBankingRates',
  href: 'https://www.gobankingrates.com',
  rel: REL_EXTERNAL,
  target: '_blank',
};

export const GO_BANKING_RATES_HOW_TO_INCREASE_CREDIT_SCORE = {
  _id: 8,
  text: 'How To Increase Credit Score?',
  href: 'https://www.gobankingrates.com/credit/credit-score/raise-credit-score-100-points/',
  rel: REL_EXTERNAL,
  target: '_blank',
};

export const GO_BANKING_RATES_WHAT_IS_A_GOOD_CREDIT_SCORE = {
  _id: 9,
  text: 'What Is A Good Credit Score?',
  href: 'https://www.gobankingrates.com/credit/credit-score/what-is-good-credit-score/',
  rel: REL_EXTERNAL,
  target: '_blank',
};

export const GO_BANKING_RATES_WHAT_IS_THE_HIGHEST_CREDIT_SCORE = {
  _id: 10,
  text: 'What Is The Highest Credit Score?',
  href: 'https://www.gobankingrates.com/credit/credit-score/what-is-highest-credit-score/',
  rel: REL_EXTERNAL,
  target: '_blank',
};

export const MEMBER_LOGIN = {
  _id: 11,
  text: 'Member Login',
  href: 'https://usa.experian.com/login/#/index?pc=aff_exp_104',
  rel: REL_EXTERNAL,
  target: '_blank',
};

export const PRIVACY_POLICY = {
  _id: 12,
  text: 'Privacy Policy & Terms of Use',
  href: '/privacy',
  rel: REL_EXTERNAL,
  target: '_blank',
};

export const SECURITY = {
  _id: 13,
  text: 'Security',
  href: 'https://__DOMAIN__/ecs/security',
  rel: REL_EXTERNAL,
  target: '_blank',
};
