import React from 'react';

import logo from 'static/images/importantscore-header-logo.svg';
import bureauLogos from 'static/images/three-bureau-logos.svg';

import { getCurrentWWW } from 'utilities/domain-detection/domain-detection';

const ISHeader = () => {
  const url = getCurrentWWW();

  return (
    <header className="ISHeader">
      <div className="container-fluid ISHeader__wrap">
        <a
          className="ISHeader__link"
          href={`https://${url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={logo}
            className="ISHeader__IS-logo"
            alt="ImportantScore.com logo"
            width="218"
            height="54"
          />
        </a>
        <img
          src={bureauLogos}
          alt="TransUnion, Experian, and Equifax logos"
          width="328"
          height="38"
        />
      </div>
    </header>
  );
};
export default ISHeader;
