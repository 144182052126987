import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  ABOUT_US,
  ADVERTISER_DISCLOSURE,
  CONTACT_US,
  FAQ,
  GBR_CREDIT_SCORES,
  GBR_HOW_TO_INCREASE_CREDIT_SCORE,
  GBR_WHAT_IS_A_GOOD_CREDIT_SCORE,
  PIDA_DO_NOT_SELL_MY_PERSONAL_INFORMATION,
  PIDA_PRIVACY_POLICY_AND_TERMS_OF_USE,
  SECURITY,
} from 'static/links/gobankingratesLinks';
import { getFormattedDomainName } from 'utilities/domain-detection/domain-detection';
import GBRLogo from 'components/GBRLogo/GBRLogo';

const WrapperProps = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export const Wrapper = ({ children, className }) => {
  return <footer className={clsx('GBRFooter', className)}>{children}</footer>;
};

Wrapper.propTypes = WrapperProps;
Wrapper.displayName = 'LandingFooterV1.Wrapper';

const ContainerProps = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export const Container = ({ children, className }) => {
  return <div className={clsx('container-fluid', 'GBRFooterContainer', className)}>{children}</div>;
};

Container.propTypes = ContainerProps;
Container.displayName = 'LandingFooterV1.Container';

export const Content = () => {
  const prettyDomain = `${getFormattedDomainName()}`;

  const col1Links = [ABOUT_US, CONTACT_US, FAQ];

  const col2Links = [
    {
      ...PIDA_DO_NOT_SELL_MY_PERSONAL_INFORMATION,
      href: PIDA_DO_NOT_SELL_MY_PERSONAL_INFORMATION.href,
    },
  ];

  const col3Links = [
    {
      ...PIDA_PRIVACY_POLICY_AND_TERMS_OF_USE,
      href: PIDA_PRIVACY_POLICY_AND_TERMS_OF_USE.href,
    },
    ADVERTISER_DISCLOSURE,
    SECURITY,
  ];

  const col4Links = [
    GBR_CREDIT_SCORES,
    GBR_WHAT_IS_A_GOOD_CREDIT_SCORE,
    GBR_HOW_TO_INCREASE_CREDIT_SCORE,
  ];

  return (
    <>
      <GBRLogo className="domainLogo" />

      <div className="footerLinks">
        <div className="linkGroup">
          <div className="linkGroupHeader">{prettyDomain}</div>

          {col1Links.map(({ text, href, rel, target }) => {
            return (
              <a key={href} href={href} rel={rel} target={target}>
                {text}
              </a>
            );
          })}
        </div>

        <div className="linkGroup">
          <div className="linkGroupHeader">Support</div>

          {col2Links.map(({ text, href, rel, target }) => {
            return (
              <a key={href} href={href} rel={rel} target={target}>
                {text}
              </a>
            );
          })}
        </div>

        <div className="linkGroup">
          <div className="linkGroupHeader">Legal</div>

          {col3Links.map(({ text, href, rel, target }) => {
            return (
              <a key={href} href={href} rel={rel} target={target}>
                {text}
              </a>
            );
          })}
        </div>

        <div className="linkGroup">
          <div className="linkGroupHeader">Helpful Resources</div>

          {col4Links.map(({ text, href, rel, target }) => {
            return (
              <a key={href} href={href} rel={rel} target={target}>
                {text}
              </a>
            );
          })}
        </div>
      </div>

      <div aria-orientation="horizontal" className="separator" role="separator" />

      <div className="footerCopy">
        <p>
          This offer is only available to <abbr title="United States">U.S.</abbr> residents.
        </p>

        <p>
          &copy; {new Date().getFullYear()} {prettyDomain}. All rights reserved. This Site is
          subject to our {prettyDomain} policies. By voluntarily providing us with information on
          this Site, you consent to our use of that information in accordance with our privacy
          policy.
        </p>

        <p>
          {prettyDomain} is not a supplier of credit monitoring products or services and does not
          ever process payments or charge consumers directly for services. If you enroll in a
          service with TransUnion you may be charged by TransUnion in accordance to the terms and
          conditions presented to you at the time of enrollment. For information on how to access or
          manage your credit monitoring subscription see the{' '}
          <a href={CONTACT_US.href} rel={CONTACT_US.rel} target={CONTACT_US.target}>
            contact us
          </a>{' '}
          page. TransUnion<sup>&reg;</sup> is a registered trademark by its owner.
        </p>

        <p>
          TransUnion Interactive, Inc. features TransUnion data for all educational credit scores.
          TransUnion Interactive, Inc. is a wholly owned subsidiary of TransUnion LLC.
        </p>

        <p>
          We define children as individuals under the age of 18. Our Website is not intended for the
          use of children and we do not knowingly solicit or collect information from children.
        </p>
      </div>
    </>
  );
};

Content.displayName = 'LandingFooterV1.Content';

const PreBuiltLandingFooterV1 = () => (
  <Wrapper>
    <Container>
      <Content />
    </Container>
  </Wrapper>
);

export default PreBuiltLandingFooterV1;
