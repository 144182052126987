import React, { lazy, Suspense } from 'react';

import {
  getCurrentDomain,
  getFormattedDomainName,
  getDomainPrefix,
} from 'utilities/domain-detection/domain-detection';
import { domainNames } from 'utilities/domain-data/domain-data';

const FooterDisclaimer = () => {
  const year = new Date().getFullYear();
  const domain = getCurrentDomain();
  const domainName = getFormattedDomainName();
  const domainWithPrefix = getDomainPrefix();

  if (domain === domainNames.GFA) {
    const GFAFooterDisclaimer = lazy(() =>
      import('components/FooterDisclaimer/GFAFooterDisclaimer')
    );

    return (
      <Suspense fallback={<div className="FooterDisclaimer">Loading...</div>}>
        <GFAFooterDisclaimer />
      </Suspense>
    );
  }

  if (domain === domainNames.IS) {
    const ISFooterDisclaimer = lazy(() => import('components/FooterDisclaimer/ISFooterDisclaimer'));

    return (
      <Suspense fallback={<div className="FooterDisclaimer">Loading...</div>}>
        <ISFooterDisclaimer />
      </Suspense>
    );
  }

  return (
    <div className="FooterDisclaimer">
      <p className="pl-3 pr-3 pl-md-0 pr-md-0">
        &copy; {year} All rights reserved. {domainName}. This Site is subject to our {domainName}{' '}
        policies. By voluntarily providing us with information on this Site, you consent to our use
        of that information in accordance with our privacy policy.
      </p>

      <p className="pl-3 pr-3 pl-md-0 pr-md-0">
        {domainName} is not a supplier of credit monitoring products or services and does not ever
        process payments or charge consumers directly for services. If you enroll in a service with
        TransUnion you may be charged by TransUnion in accordance to the terms and conditions
        presented to you at the time of enrollment. For information on how to access or manage your
        credit monitoring subscription see the &nbsp;
        <a href={`https://${domainWithPrefix}/contact`}>contact us</a> page.
      </p>

      <p className="pl-3 pr-3 pl-md-0 pr-md-0">This offer is only available to U.S. residents.</p>

      <p className="pl-3 pr-3 pl-md-0 pr-md-0">
        TransUnion&reg; is a registered trademark by its owner.
      </p>

      <p className="pl-3 pr-3 pl-md-0 pr-md-0">
        TransUnion Interactive features TransUnion data for all educational credit scores as well as
        fraud-watch emails. TransUnion interactive is a wholly owned subsidiary of TransUnion LLC.
      </p>

      <p className="pl-3 pr-3 pl-md-0 pr-md-0">
        We define children as individuals under the age of 16. Our Website is not intended for the
        use of children and we do not knowingly solicit or collect information from children.
      </p>
    </div>
  );
};

export default FooterDisclaimer;
