import React from 'react';
import htmr from 'htmr';

import logo from 'static/images/importantscore-footer-logo.svg';
import {
  ABOUT_US,
  ADVERTISER_DISCLOSURE,
  CONTACT,
  DO_NOT_SELL,
  FAQ,
  GO_BANKING_RATES,
  GO_BANKING_RATES_HOW_TO_INCREASE_CREDIT_SCORE,
  GO_BANKING_RATES_WHAT_IS_A_GOOD_CREDIT_SCORE,
  GO_BANKING_RATES_WHAT_IS_THE_HIGHEST_CREDIT_SCORE,
  MEMBER_LOGIN,
  PRIVACY_POLICY,
  SECURITY,
} from 'components/ISFooter/links';
import { getDomainPrefix } from 'utilities/domain-detection/domain-detection';

const col1Links = [ABOUT_US, FAQ, MEMBER_LOGIN];
const col2Links = [CONTACT];
const col3Links = [PRIVACY_POLICY, ADVERTISER_DISCLOSURE, SECURITY, DO_NOT_SELL];
const gbrLinks = [
  GO_BANKING_RATES,
  GO_BANKING_RATES_WHAT_IS_A_GOOD_CREDIT_SCORE,
  GO_BANKING_RATES_HOW_TO_INCREASE_CREDIT_SCORE,
  GO_BANKING_RATES_WHAT_IS_THE_HIGHEST_CREDIT_SCORE,
];

const ISFooter = () => {
  const copyrightYear = new Date().getFullYear();
  const url = getDomainPrefix();

  return (
    <footer className="ISFooter">
      <div className="container">
        <div className="content">
          <div className="row">
            <div className="col">
              <img src={logo} alt="Logo for www.importantscore.com" className="footer-logo" />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="row">
                <div className="col-6 col-md-3 col-lg-6 ">
                  <ul className="footer-link-group">
                    {col1Links.map(({ _id, href, text, ...rest }) => (
                      <li key={_id}>
                        {/* eslint-disable */}
                        <a
                          href={`${href.replace('__DOMAIN__', url)}`}
                          className="footer-link-group-anchor"
                          {...rest}
                        >
                          {htmr(text)}
                        </a>
                        {/* eslint-enable */}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="col-6 col-md-3 col-lg-6">
                  <ul className="footer-link-group">
                    {col2Links.map(({ _id, href, text, ...rest }) => (
                      <li key={_id}>
                        {/* eslint-disable */}
                        <a
                          href={`${href.replace('__DOMAIN__', url)}`}
                          className="footer-link-group-anchor"
                          {...rest}
                        >
                          {text}
                        </a>
                        {/* eslint-enable */}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="col-12 col-md-6 col-lg-12">
                  <span className="footer-link-group-heading">Helpful Resources</span>
                  <ul className="footer-link-group">
                    {gbrLinks.map(({ _id, href, text, ...rest }) => (
                      <li key={_id}>
                        {/* eslint-disable-next-line */}
                        <a href={href} className="footer-link-group-anchor" {...rest}>
                          {text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <ul className="footer-other-links">
                {col3Links.map(({ _id, href, text, ...rest }) => (
                  <li key={_id}>
                    {/* eslint-disable */}
                    <a
                      href={`${href.replace('__DOMAIN__', url)}`}
                      className="footer-other-links-anchor"
                      {...rest}
                    >
                      {text}
                    </a>
                    {/* eslint-disable */}
                  </li>
                ))}
              </ul>
              <hr className="footer-separator" />
              <div className="footer-copy">
                <p>This offer is only available to U.S. residents.</p>

                <p>
                  &copy; {copyrightYear} All rights reserved. ImportantScore.com. This site is
                  subject to our ImportantScore.com policies. By voluntarily providing us with
                  information on this site, you consent to our use of that information in accordance
                  with our privacy policy.
                </p>

                <p>
                  Experian and the Experian marks used herein are trademarks or registered
                  trademarks of Experian Information Solutions, Inc. Other product and company names
                  mentioned herein are the property of their respective owners.
                </p>

                <p>
                  The Site is not directed to individuals under the age of eighteen (18), and we
                  request that such individuals not provide personal information through the Site.
                  If you are under 18 years of age, you may browse our Site; however, you may not
                  provide personal information to us such as name, address, or email address, and
                  you may not register for, enroll in, and/or make product purchases.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default ISFooter;
