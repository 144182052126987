import React from 'react';

import logo from 'static/images/gofreecredit-header-logo.svg';
import { getCurrentWWW } from 'utilities/domain-detection/domain-detection';

const GFCHeader = () => {
  const url = getCurrentWWW();

  return (
    <header className="GFCHeader">
      <div className="container-fluid GFCHeader__wrap">
        <a
          className="GFCHeader__link"
          href={`https://${url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo} alt="GOFreeCredit logo" />
        </a>
      </div>
    </header>
  );
};
export default GFCHeader;
