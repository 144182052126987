import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Header from 'components/Header/Header';
import ISHeader from 'components/ISHeader/ISHeader';
import GFCHeader from 'components/GFCHeader/GFCHeader';
import Footer from 'components/Footer/Footer';
import GFCFooter from 'components/GFCFooter/GFCFooter';
import GBRFooter from 'components/GBRFooter/GBRFooter';
import ISFooter from 'components/ISFooter/ISFooter';

import { getCurrentDomain, getCurrentView } from 'utilities/domain-detection/domain-detection';

import { domainNames, metaData } from 'utilities/domain-data/domain-data';

const FooterComponent = () => {
  const view = getCurrentView();

  switch (view) {
    case domainNames.CCT:
    case domainNames.GFA:
    case domainNames.YSO:
    case domainNames.GOIP:
      return <Footer />;
    case domainNames.GBR:
      return <GBRFooter />;
    case domainNames.IS:
      return <ISFooter />;
    default:
      return <GFCFooter />;
  }
};

const HeaderComponent = () => {
  const view = getCurrentView();

  switch (view) {
    case domainNames.CCT:
    case domainNames.GFA:
    case domainNames.YSO:
    case domainNames.GOIP:
    case domainNames.GBR:
      return <Header />;
    case domainNames.IS:
      return <ISHeader />;
    default:
      return <GFCHeader />;
  }
};

const withPageLayout = (PageComponent, { pageTitle, isIndexed = false } = {}) => {
  return (props) => {
    useEffect(() => {
      if (pageTitle) {
        document.title = metaData[getCurrentDomain()].titles[pageTitle];
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <Helmet>
          <meta name="robots" content={isIndexed ? 'index, follow' : 'noindex, nofollow'} />
        </Helmet>

        <HeaderComponent />

        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <PageComponent {...props} />

        <FooterComponent />
      </>
    );
  };
};

export default withPageLayout;
