import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import { useLocation } from 'react-router';
import withPageLayout from 'hocs/withPageLayout';

import ManageMyDataForm from 'components/ManageMyDataForm/ManageMyDataForm';

import { getCurrentView, getDomainPrefix } from 'utilities/domain-detection/domain-detection';
import { domainNames } from 'utilities/domain-data/domain-data';

import routes from 'utilities/routes-helper/routes-helper';

const privacyAndTermsProps = {
  currentView: PropTypes.string,
};

// INFO: Point back to GFA's privacy policy & terms of use
const PrivacyAndTerms = React.memo(({ currentView }) => {
  switch (currentView) {
    case domainNames.GFA:
      return (
        <>
          <a href={`https://${getDomainPrefix()}/privacy`} rel="nofollow noopener noreferrer">
            Privacy Policy
          </a>{' '}
          &amp;{' '}
          <a href={`https://${getDomainPrefix()}/terms`} rel="nofollow noopener noreferrer">
            Terms of Use
          </a>
        </>
      );

    default:
      return <Link to={routes('privacyPolicy')}>Privacy Policy &amp; Terms of Use</Link>;
  }
});
PrivacyAndTerms.propTypes = privacyAndTermsProps;

const ManageMyDataPage = () => {
  const location = useLocation();

  const query = location.search ? queryString.parse(location.search) : '';
  const currentView = getCurrentView();

  return (
    <div>
      <div className="container-fluid Page ManageMyDataPage">
        <div className="row">
          <div className="col-12">
            <h1>Manage My Data</h1>

            <p>
              Your privacy is important to us. Under our{' '}
              <PrivacyAndTerms currentView={currentView} />, you have the right to access, know
              about, and delete all of your personal information we collect within the past 12
              months up to 2 times within 12-month period. We will respond to your request promptly
              within a reasonable timeframe.
            </p>

            <p>
              We will need the following information provided below to verify your identity to make
              the request and confirm the personal information related to you.
            </p>

            <p>
              <strong>Please select your request and provide the following: </strong>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <ManageMyDataForm query={query} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPageLayout(ManageMyDataPage, {
  isIndexed: true,
  pageTitle: 'manageMyData',
});
