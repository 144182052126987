import React, { lazy, Suspense } from 'react';

import FooterLink from 'components/FooterLink/FooterLink';

import {
  getCurrentDomain,
  getCurrentURL,
  getDomainPrefix,
  getFormattedDomainName,
  isTuDomainName,
} from 'utilities/domain-detection/domain-detection';
import { domainNames } from 'utilities/domain-data/domain-data';
import { footerLinks } from 'utilities/labels/labels';

const GFAFooterLinks = lazy(() => import('components/FooterLinks/GFAFooterLinks'));

const FooterLinks = () => {
  const url = getCurrentURL();
  const www = isTuDomainName() ? `${getDomainPrefix()}/tu/footer` : getDomainPrefix();
  const domainName = getFormattedDomainName();
  const domain = getCurrentDomain();

  if (domain === domainNames.GFA) {
    return (
      <Suspense fallback="Loading...">
        <GFAFooterLinks />
      </Suspense>
    );
  }

  return (
    <>
      <div className="col-6 col-md-3 mt-3 mt-md-0">
        <FooterLink header label={domainName} key={domainName} />

        <FooterLink label={footerLinks.about} url={`https://${www}/about`} />

        <FooterLink label={footerLinks.faq} url={`https://${www}/faq`} />

        <FooterLink label={footerLinks.login} url={`https://${www}/memberlogin`} />

        <FooterLink label={footerLinks.contact} url={`https://${www}/contact`} />
      </div>

      <div className="col-6 col-md-3 mt-3 mt-md-0">
        <FooterLink header label={footerLinks.support} />

        <FooterLink label={footerLinks.unsubscribe} url={`https://${www}/unsubscribe`} />

        <FooterLink label={footerLinks.cancellation} url={`https://${www}/membercancel`} />

        <FooterLink label={footerLinks.manageMyData} url={`https://${url}/manage-my-data`} />

        <FooterLink label={footerLinks.doNotSell} url={`https://${url}/do-not-sell`} />
      </div>

      <div className="col-6 col-md-3 mt-3 mt-md-0">
        <FooterLink header label={footerLinks.legal} />

        <FooterLink label={footerLinks.privacy} url={`https://${url}/privacy`} />

        <FooterLink
          label={footerLinks.advertiserDisclosure}
          url={`https://${www}/advertiser-disclosure`}
        />

        <FooterLink label={footerLinks.security} url={`https://${www}/security`} />
      </div>

      <div className="col-6 col-md-3 mt-3 mt-md-0">
        <FooterLink header label={footerLinks.resources} />

        <FooterLink
          label={footerLinks.goBanking}
          external
          url="https://www.gobankingrates.com/category/credit/credit-score/"
        />

        <FooterLink
          label={footerLinks.goodScore}
          external
          url="https://www.gobankingrates.com/credit/credit-score/what-good-credit-score-anyway/"
        />

        <FooterLink
          label={footerLinks.increaseScore}
          external
          url="https://www.gobankingrates.com/credit/credit-score/raise-credit-score-100-points/"
        />

        <FooterLink
          label={footerLinks.repairScore}
          external
          url="https://www.gobankingrates.com/credit/credit-monitoring/best-credit-repair-companies/"
        />
      </div>
    </>
  );
};

export default FooterLinks;
