import React from 'react';
import { Link } from 'react-router-dom';

import withPageLayout from 'hocs/withPageLayout';

import ConfirmationImg from 'components/ConfirmationImg/ConfirmationImg';

import routes from 'utilities/routes-helper/routes-helper';

const ErrorPage = () => {
  return (
    <div className="container-fluid Page">
      <div className="row align-items-center justify-content-center Page__image">
        <ConfirmationImg />
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-12 text-center">
          <h2 className="Page__header">Something Went Wrong</h2>

          <div className="Page__content">
            Either you have already used the link sent to your email or the 24-hour time period to
            click that link has expired. Please click the button below and re-submit your request.
            Thank you.
          </div>

          <Link to={routes('manageMyData')} data-testid="resubmit-my-request">
            <button className="Page__cta" type="button">
              Re-Submit My Request
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withPageLayout(ErrorPage, {
  pageTitle: 'error',
});
