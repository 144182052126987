import queryString from 'query-string';

import { domainNames } from 'utilities/domain-data/domain-data';

export const getViewParameter = () => {
  const page = window.location.search ? queryString.parse(window.location.search) : '';
  return page.view;
};

export const getCurrentDomain = () => {
  const pageView = getViewParameter();
  const domain = window.location.hostname.split('.');

  if (domain[0] === 'localhost' && domainNames[pageView]) {
    domain[0] = domainNames[pageView];
  }

  if (domain[0] === 'localhost' && !domainNames[pageView]) {
    domain[0] = domainNames.GFC;
  }

  if (domain[0] === 'stage-member' && domainNames[pageView]) {
    domain[1] = domainNames[pageView];
  }

  return domain.length >= 3 ? domain[1] : domain[0];
};

export const getCurrentSubDomain = () => {
  const domain = window.location.hostname.split('.');

  if (domain[0] === 'localhost') {
    domain[0] = 'stage-member';
  }

  return domain[0];
};

export const getCurrentURL = () => {
  return `${getCurrentSubDomain()}.${getCurrentDomain()}.com`;
};

export const getCurrentWWW = () => {
  return `www.${getCurrentDomain()}.com`;
};

export const getDomainPrefix = () => {
  const domain = window.location.hostname.split('.');

  if (domain[0] === 'localhost' || domain[0] === 'stage-member') {
    domain[0] = 'stage-';
  } else {
    domain[0] = '';
  }

  return `${domain[0]}www.${getCurrentDomain()}.com`;
};

export const getFormattedDomainName = () => {
  let domain = getCurrentDomain();

  switch (domain) {
    case domainNames.CCT:
      domain = 'CreditCheckingToday';
      break;
    case domainNames.GBR:
      domain = 'GOBankingRates';
      break;
    case domainNames.GFA:
      domain = 'GOFinancialAdvice';
      break;
    case domainNames.GOIP:
      domain = 'GOIdentityProtect';
      break;
    case domainNames.IS:
      domain = 'ImportantScore';
      break;
    case domainNames.YSO:
      domain = 'YourScoreOnline';
      break;
    default:
      domain = 'GOFreeCredit';
      break;
  }

  return `${domain}.com`;
};

export const isTuDomainName = () => {
  const domain = getCurrentDomain();
  let isTu = false;

  switch (domain) {
    case domainNames.CCT:
      isTu = true;
      break;
    case domainNames.GOIP:
      isTu = true;
      break;
    case domainNames.YSO:
      isTu = true;
      break;
    case domainNames.GFC:
      isTu = true;
      break;
    default:
      isTu = false;
      break;
  }

  return isTu;
};

export const getFormattedName = () => {
  const formattedDomainName = getFormattedDomainName();
  return formattedDomainName.replace(/\.com$/g, '');
};

export const getCurrentView = () => {
  const pageView = getViewParameter();
  const domain = window.location.hostname.split('.');

  if (domain[0] === 'localhost') {
    return domainNames[pageView];
  }

  return getCurrentDomain();
};
